@import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;800;900&display=swap");

:root {
  --color-codes-hex: [#ef5a20, #232249, #be073f, #07376d, #ce7e5a, #a691da];
  --color-codes-rgb: [rgb(239 90 32), rgb(35 34 73), rgb(190 7 63),
    rgb(7 55 109), rgb(206 126 90), rgb(166 145 218) ];

  --bg-main-color: #243e52;
  --bg-main-color: #0dcaf0;
  --bg-main-color: #07376d;
  --bg-main-color: #a691da;
  --font-color: #fff;
  --font-color-hover: #000;
}
$accordion-color: rgba(var(--bg-color), 1) 100%;
$accordion-bg: rgba(var(--bg-color), 1) 100%;
$accordion-button-color: rgba(var(--bg-color), 1) 100%;
body {
  margin: 0;
  font-family: "Prompt", Helvetica, Arial, Lucida, sans-serif;
  color: var(--font-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: linear-gradient(
  //   90deg,
  //   rgba(var(--bg-color), 0.9) 0%,
  //   rgba(var(--bg-color), 1) 100%
  // );
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 1%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    linear-gradient(90deg, var(--bg-main-color), var(--bg-main-color));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.illustration {
  &__right {
    &__image {
      // position: relative;

      // background-image: url("./images/wrapper.png"), url("./images/saswat.jpg"),
      // linear-gradient(rgba(var(--bg-color), 0.8), rgba(var(--bg-color), 0.8));
      // background-image: url("./images/wrapper-rotate.png"),
      //   url("./images/saswat.jpg"),
      //   linear-gradient(rgba(var(--bg-color), 0.8), rgba(var(--bg-color), 0.8));

      // background-image: url("./images/wrapper-rotate.png"),
      //   url("./images/saswat.jpg"),
      //   linear-gradient(var(--bg-main-color), var(--bg-main-color));

      background-image: url("./images/wrapper-rotate.png"),
        url("./images/saswat.jpg");
      background-size: 100% auto, 77% auto, 100% auto;

      height: 90vh;
      background-position: center;
      transform: rotate(-3deg);
      background-repeat: no-repeat;
      background-blend-mode: lighten, normal, normal;
      background-blend-mode: screen;
      top: -15px;
    }
  }
}
@media (min-width: 1200px) {
  .illustration {
    &__right {
      position: sticky;
      top: 20px;
      &__image {
        // background-size: 31rem auto, 25rem auto, 31rem;
      }
    }
  }
}

@media (min-width: 992px) {
  .illustration {
    &__right {
      // position: sticky;
      // top: 20px;
      &__image {
        background-size: 31rem auto, 25rem auto, 31rem;
      }
    }
  }
}

.accordion {
  transform: translateZ(0);
  // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.32);
  &:last-child {
    border-bottom: none;
  }

  & > .accordion-toggle {
    position: absolute;
    opacity: 0;
    display: none;

    &:checked ~ label {
      & h2 {
        text-shadow: 2px 2px 0rem var(--font-color-hover);
        transition: 0.3s all;
      }
    }
    &:checked ~ label::after {
      transform: rotate(0deg);
    }

    &:checked ~ label:hover {
      color: var(--font-color);
    }

    &:checked ~ section {
      // height: 400px;
      // height: max-content;
      // height: 100%;

      max-height: 9999px;
      transition-timing-function: cubic-bezier(0.5, 0, 1, 0);
      transition-delay: 0s;
    }
  }

  & > label {
    position: relative;
    display: block;
    cursor: pointer;
    &:hover {
      color: var(--font-color-hover);

      & p {
        color: var(--font-color);
        text-shadow: rgba(255, 255, 255, 0.32);
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 20px;
      font-family: fontawesome;
      transform: rotate(90deg);
      transition: 0.3s transform;
    }
  }

  & > section {
    // height: 0;
    // transition: 0.3s all;
    // outline: dotted;
    overflow: hidden;

    max-height: 0px;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1) -0.1s;
  }
  & > section p {
    // margin: 15px 0;
    // padding: 0 20px;
    // padding: 20px;
  }
  &__tags {
    border: 1px solid var(--font-color);
    margin-right: 10px;
    font-weight: 500;
    width: auto;
    display: inline-block;
    font-size: 11px;
    line-height: 1.33333em;
    padding: 7px 18px;
    margin-top: 0.8em;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
  }
  & a {
    text-decoration: none !important;
    & span:hover {
      color: var(--font-color-hover);
      transition: 0.3s color;
    }
  }
}

.resume {
  & a {
    text-decoration: none !important;
    & p:hover {
      transform: scale(1.2);
      color: var(--font-color-hover);
      transition: 0.3s transform;
    }
  }
}

.sayHello {
  & a {
    text-decoration: none !important;
    & p:hover {
      color: var(--font-color-hover);
      transition: 0.3s color;
    }
  }
}

.experiment {
  & div {
    height: 300px;
    outline: dotted;
  }
}
